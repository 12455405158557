import React, { MouseEventHandler } from 'react';

import { REVIEW_QUESTION_TYPES, REVIEW_RATING_TYPE } from '@learned/constants';
import { I18n } from '@lingui/core';
import { t } from '@lingui/macro';

import { ICONS, Icon } from '~/components/Icon';
import Tooltip, { TOOLTIP_SIZES } from '~/components/Tooltip';
import { Cell } from '~/pages/Reports/Components/Cell';
import CollapseIndicator from '~/pages/Reports/Components/IconWrapper';
import { Nester, NormalCell, NormalCellCtr } from '~/pages/Reports/engagement/tabs/CustomStyles';
import { MENU_SIZE } from '~/pages/Reports/options';

import { ColumnPosition } from '~/@types/table';

import { TResultColumn, TReviewResultRow } from '../../../types';
import { CellContentWrapper, TableColumnBodyCell } from '../design';
import FirstColumnHeaderCell from '../FirstColumnHeaderCell';
import OtherColumnHeaderCell from '../OtherColumnHeaderCell';

export const createColumns = (columns: TResultColumn[], i18n: I18n) => {
  const tableColumns = columns.map((heatmapColumn) => {
    if (heatmapColumn.id === 'primaryDimension') {
      return {
        ...heatmapColumn,
        id: heatmapColumn.id,
        name: () => {
          return <FirstColumnHeaderCell column={heatmapColumn} />;
        },
        accessor: `${heatmapColumn.id}`,
        renderCell: (
          cell: TReviewResultRow,
          onClick: MouseEventHandler<HTMLDivElement> | undefined,
        ) => {
          const displayName = cell.name;
          return (
            <NormalCellCtr
              className="cell rounded-corners"
              clickable={true}
              onClick={() => {
                /* @ts-ignore */
                onClick ? onClick(cell) : {};
              }}
            >
              <Tooltip tooltip={displayName} size={TOOLTIP_SIZES.BIG}>
                <NormalCell mWidth={`${MENU_SIZE.LEFT_FULL_WIDTH}px`}>
                  <CellContentWrapper
                    isClickable={
                      (cell.id !== 'companyAverage' && !cell.children?.length) ||
                      (cell.children && cell.children?.length > 0)
                    }
                    role="button"
                    tabIndex={0}
                  >
                    <Nester nestLevel={cell.nestedLevel} />
                    <div className={'cell-dimension-section'}>
                      {cell.children && cell.children.length > 0 && (
                        <CollapseIndicator isCollapsed={cell.isCollapsed} disablePointerEvents />
                      )}
                      <span className="text-content-wrapper">{displayName}</span>
                    </div>
                  </CellContentWrapper>
                </NormalCell>
              </Tooltip>
            </NormalCellCtr>
          );
        },
        isFixed: true,
        centerAlign: true,
        position: ColumnPosition.LEFT,
        maxWidth: '200px',
        minWidth: '110px',
        padding: '7px',
        showHeaderTooltip: true,
        sortBy: {
          asc: {
            key: `${heatmapColumn.id}__asc`,
            label: (i18n: I18n) => i18n._(t`A-Z Alphabetic`),
          },
          desc: {
            key: `${heatmapColumn.id}__desc`,
            label: (i18n: I18n) => i18n._(t`Z-A Alphabetic`),
          },
        },
      };
    }
    return {
      ...heatmapColumn,
      id: heatmapColumn.id,
      name: () => {
        return <OtherColumnHeaderCell column={heatmapColumn} />;
      },
      accessor: `${heatmapColumn.id}`,
      // render main table cell (middle table)
      renderCell: (row: TReviewResultRow) => {
        const cellValue = row.cells.find((c) => c.measure === heatmapColumn.id);
        if (!cellValue) {
          return (
            <TableColumnBodyCell key={heatmapColumn.id}>
              <></>
            </TableColumnBodyCell>
          );
        }

        if (heatmapColumn.questionType === REVIEW_QUESTION_TYPES.GOAL_PLAN) {
          return (
            <TableColumnBodyCell key={`${cellValue.measure}`}>
              {cellValue?.value}
            </TableColumnBodyCell>
          );
        } else {
          const evaluators = [];
          if (heatmapColumn.showCoach) {
            evaluators.push(REVIEW_RATING_TYPE.COACH);
          }
          if (heatmapColumn.showPeer) {
            evaluators.push(REVIEW_RATING_TYPE.PEER);
          }
          if (heatmapColumn.showSelf) {
            evaluators.push(REVIEW_RATING_TYPE.SELF);
          }
          return evaluators.map((reviewerType, index) => {
            let value = 0;
            switch (reviewerType) {
              case REVIEW_RATING_TYPE.COACH:
                value = cellValue?.value || 0;
                break;
              case REVIEW_RATING_TYPE.PEER:
                value = cellValue?.peer || 0;
                break;
              case REVIEW_RATING_TYPE.SELF:
                value = cellValue?.self || 0;
                break;
            }
            // Not answer available
            if (value === -1002) {
              return (
                <TableColumnBodyCell key={`${cellValue.measure} - ${index}`}>
                  <></>
                </TableColumnBodyCell>
              );
            }
            // Answered as Not Applicable
            if (value === -1001) {
              return (
                <TableColumnBodyCell key={`${cellValue.measure} - ${index}`}>
                  {i18n._(t`N/A`)}
                </TableColumnBodyCell>
              );
            }

            if (heatmapColumn.questionType === REVIEW_QUESTION_TYPES.TEXT) {
              return (
                <TableColumnBodyCell key={`${cellValue.measure} - ${index}`}>
                  {value > 0 ? <Icon icon={ICONS.COMMENT} /> : <></>}
                </TableColumnBodyCell>
              );
            } else if (
              [
                REVIEW_QUESTION_TYPES.SKILL_CATEGORY,
                REVIEW_QUESTION_TYPES.RATING,
                REVIEW_QUESTION_TYPES.CUSTOM_SKILL,
              ].includes(heatmapColumn.questionType as REVIEW_QUESTION_TYPES)
            ) {
              return (
                <Cell
                  key={`${cellValue.measure} - ${index}`}
                  value={(100 / (cellValue?.rlc as number)) * value || 0}
                  noColor={false}
                  onClick={() => {}}
                >
                  {value}/{cellValue?.rlc}
                </Cell>
              );
            } else if (heatmapColumn.id === 'average') {
              return (
                <Cell
                  key={`${cellValue.measure} - ${index}`}
                  value={(100 / (cellValue?.rlc as number)) * value || 0}
                  noColor={false}
                  onClick={() => {}}
                >
                  {value}/{cellValue?.rlc}
                </Cell>
              );
            } else {
              return (
                <TableColumnBodyCell key={index}>
                  <></>
                </TableColumnBodyCell>
              );
            }
          });
        }
      },
      isFixed: heatmapColumn.id === 'average' || heatmapColumn.id === 'benchmark',
      position: ColumnPosition.RIGHT,
      colSpan: getColSpan(heatmapColumn),
      maxWidth: '20px',
      minWidth: '150px',
      padding: '7px',
      centerAlign: true,
      showHeaderTooltip: true,
    };
  });

  return tableColumns;
};

const getColSpan = (column: TResultColumn) => {
  let colSpan = 0;
  if (column.questionType === REVIEW_QUESTION_TYPES.GOAL_PLAN) {
    colSpan = 1;
  } else {
    if (column.showCoach) {
      colSpan++;
    }
    if (column.showPeer) {
      colSpan++;
    }
    if (column.showSelf) {
      colSpan++;
    }
  }
  return colSpan;
};
