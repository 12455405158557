import React, { type FC, useState } from 'react';

import {
  REVIEW_QUESTION_EVALUATORS,
  REVIEW_QUESTION_TYPES,
  TASK_STATUS,
  TASK_TYPE,
  USER_REVIEW_STATUS,
} from '@learned/constants';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { MultiLangComponent } from '~/components/Dropdown/MultiLangualDropdown';
import { ActionItemBlock, Actions } from '~/components/FillOutSurvey/design';
import { ICONS } from '~/components/Icon';
import Label from '~/components/Label';
import { UserPreviewDrawer } from '~/components/UserPreviewDrawer';
import { useReviewRatingsState } from '~/pages/ReviewGiveFeedback/components/GiveReview.hooks';
import { InputNoLongerPossible } from '~/pages/ReviewGiveFeedback/components/InputNoLongerPossible';
import { getInviteDefaultMessage } from '~/pages/Reviews/Modals/NominatePeers/InvitationMessage/getInviteDefaultMessage';
import { Center, PreviewBar } from '~/pages/SurveyTaskView/design';

import { CoachBanner, Container, DarkBackground, HeaderWrapper } from './GiveReview.design';
import { Header } from './Header';
import { PeerReviewHeader } from './PeerReviewHeader';
import { QuestionsView } from './QuestionsView';
import { ReviewIntro } from './ReviewIntro';

import { useFromQuery } from '~/hooks/useFromQuery';
import type { ILanguageStateReturn } from '~/hooks/useLanguageState';
import { getUser } from '~/selectors/baseGetters';
import { COLORS } from '~/styles';

import type { IPopulatedReviewTask, IPopulatedUserReview } from '../types';
import type { IUser } from '@learned/types';

export const LastSavedWrapper = styled.div``;

export const Wrapper = styled.div`
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 5px;
  margin-right: 24px;
  cursor: pointer;
`;

export const StyledLabel = styled(Label)`
  margin-left: 44px;
  border: solid 1px ${COLORS.PLACEHOLDERS};
  background-color: ${COLORS.BG_ELEMENTS};
`;

export const DisabledBadge = styled.div`
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 100px;
  background-color: ${COLORS.STATUS_ARCHIVED};
`;

function GiveReview({
  reviewTask,
  userReview,
  introTitle,
  companyLogo,
  onDecline,
  userFrom,
  DeclinedScreen,
  ExpiredScreen,
  languageState,
  useMultiLangString,
  userFromObject,
  InputNoLongerPossibleScreen,
  isPreview,
  onBack,
  onEdit,
  editedQuestion,
}: {
  reviewTask: IPopulatedReviewTask;
  userReview: IPopulatedUserReview;
  introTitle: string;
  companyLogo?: string;
  userFrom?: string;
  userFromObject?: IUser;
  languageState: ILanguageStateReturn;
  useMultiLangString: () => (multiLangString: Record<string, string> | string) => string;
  onDecline?: () => void;
  DeclinedScreen?: FC;
  ExpiredScreen?: FC;
  InputNoLongerPossibleScreen?: React.ReactNode;
  isPreview?: boolean;
  onBack?: () => void;
  onEdit?: (id: string) => void;
  editedQuestion?: string;
}) {
  const { i18n } = useLingui();
  const { goBack } = useFromQuery({ includeHash: true });

  const history = useHistory();

  const currentUser = useSelector(getUser);
  const isAdmin = currentUser?.isAdmin; // outside review does not have currentUser, so this will be undefined

  const [showIntro, setShowIntro] = useState(reviewTask.status === TASK_STATUS.TODO);
  const showingIntro = !isPreview && reviewTask && userReview && showIntro;

  const reviewRatingsState = useReviewRatingsState({
    reviewTask,
    userReview,
    languageState,
    useMultiLangString,
    isPreview,
    editedQuestion,
  });
  const isExternalPeer = reviewTask.userTo?.email;
  const { showOutro, currentQuestion, showExternalConfirmation, validationModalToggle } =
    reviewRatingsState;
  const showDeclinedScreen = DeclinedScreen && reviewTask.status === TASK_STATUS.DECLINED;
  const showExpiredScreen = ExpiredScreen && reviewTask.status === TASK_STATUS.EXPIRED;
  const isTaskLocked = [
    TASK_STATUS.EXPIRED,
    TASK_STATUS.DECLINED,
    TASK_STATUS.UPCOMING,
    ...(isExternalPeer ? [TASK_STATUS.COMPLETED] : []),
  ].includes(reviewTask.status);

  const isReviewLocked = [
    USER_REVIEW_STATUS.ARCHIVED,
    USER_REVIEW_STATUS.PUBLISHED,
    USER_REVIEW_STATUS.DRAFT,
  ].includes(userReview.status);

  const isReviewWithDigitalSignLocked =
    userReview.settings.isDigitalSign &&
    [USER_REVIEW_STATUS.SIGNING, USER_REVIEW_STATUS.COMPLETED].includes(userReview.status);

  const showCompletedScreen =
    [...(!isExternalPeer ? [TASK_STATUS.COMPLETED] : [])].includes(reviewTask.status) &&
    !isReviewWithDigitalSignLocked &&
    !isReviewLocked &&
    history.action === 'POP'; // Only show when page is open from email link, not affecting the edit action inside userReviewDashboard

  const showInputNoLongerPossible =
    InputNoLongerPossibleScreen &&
    (isTaskLocked || isReviewLocked || isReviewWithDigitalSignLocked);

  const isNonCoachQuestion =
    currentQuestion.type !== REVIEW_QUESTION_TYPES.GOAL_PLAN &&
    !currentQuestion.settings.evaluators.includes(REVIEW_QUESTION_EVALUATORS.COACH);
  const isCoach = reviewTask.type === TASK_TYPE.REVIEW_COACH_EVALUATE;

  const getInviteMessage = () => {
    const isPeerEvaluate = reviewTask.type === TASK_TYPE.REVIEW_PEER_EVALUATE;
    const defaultMessage = getInviteDefaultMessage(currentUser?.firstName || '');
    return isPeerEvaluate ? reviewTask.description ?? defaultMessage : userReview.description;
  };

  return (
    <>
      <HeaderWrapper>
        {isExternalPeer ? (
          <PeerReviewHeader
            companyLogo={companyLogo}
            userReview={userReview}
            reviewTask={reviewTask}
            hideActions={
              showingIntro ||
              Boolean(showDeclinedScreen) ||
              Boolean(showExpiredScreen) ||
              Boolean(showInputNoLongerPossible) ||
              showExternalConfirmation.value
            }
            useMultiLangString={useMultiLangString}
            reviewRatingsState={reviewRatingsState}
          />
        ) : (
          <Header
            goBack={isPreview && onBack ? onBack : goBack}
            userReview={userReview}
            reviewTask={reviewTask}
            isPreview={isPreview}
            hideActions={
              !isPreview &&
              (showingIntro ||
                Boolean(showDeclinedScreen) ||
                Boolean(showExpiredScreen) ||
                Boolean(showInputNoLongerPossible))
            }
            // @ts-ignore
            subHeader={
              isPreview && (
                <PreviewBar>
                  <Center>{i18n._(t`This is a preview — answers won’t be saved`)}</Center>
                </PreviewBar>
              )
            }
            actions={
              <Actions>
                {isPreview && (
                  <ActionItemBlock>
                    <MultiLangComponent isSingleSelect languageState={languageState} />
                  </ActionItemBlock>
                )}
                {isPreview && onEdit && isAdmin && (
                  <ActionItemBlock>
                    <Button
                      label={i18n._(t`Edit question`)}
                      icon={ICONS.EDIT_PENCIL}
                      size={ButtonSize.MEDIUM}
                      variant={ButtonVariant.TEXT_PRIMARY}
                      onClick={() => onEdit(currentQuestion.id)}
                    />
                  </ActionItemBlock>
                )}
              </Actions>
            }
            useMultiLangString={useMultiLangString}
            reviewRatingsState={reviewRatingsState}
          />
        )}
      </HeaderWrapper>
      {showCompletedScreen && <InputNoLongerPossible status={TASK_STATUS.COMPLETED} />}
      {showDeclinedScreen && <DeclinedScreen />}
      {showExpiredScreen && <ExpiredScreen />}
      {!showCompletedScreen && showInputNoLongerPossible && InputNoLongerPossibleScreen}
      {!showDeclinedScreen &&
        !showExpiredScreen &&
        !showInputNoLongerPossible &&
        !showCompletedScreen && (
          <>
            {!showOutro && !showingIntro && isCoach && isNonCoachQuestion && (
              <CoachBanner>
                <Trans>This question is not part of the coach review</Trans>
              </CoachBanner>
            )}
            <Container>
              {!showOutro && !showingIntro && isCoach && isNonCoachQuestion && <DarkBackground />}
              {showingIntro && (
                <ReviewIntro
                  onClose={() => goBack()}
                  onStart={() => setShowIntro(false)}
                  reviewTitle={introTitle}
                  reviewMessage={getInviteMessage()}
                  companyLogo={companyLogo}
                  onDecline={onDecline}
                  userFrom={userFrom}
                  userFromObject={userFromObject}
                  useMultiLangString={useMultiLangString}
                  isExternalPeer={Boolean(isExternalPeer)}
                />
              )}
              {!showingIntro && (
                <QuestionsView
                  reviewRatingsState={reviewRatingsState}
                  reviewTask={reviewTask}
                  userReview={userReview}
                  isCoach={isCoach}
                  useMultiLangString={useMultiLangString}
                  userFromObject={userFromObject}
                  isPreview={isPreview}
                  validationModalToggle={validationModalToggle}
                />
              )}
            </Container>
          </>
        )}
      {/* don't show it for intro screen */}
      {!showingIntro &&
        userReview.createdFor &&
        // only for self review and coach (not for peers)
        [TASK_TYPE.REVIEW_SELF_EVALUATE, TASK_TYPE.REVIEW_COACH_EVALUATE].includes(
          reviewTask.type,
        ) && <UserPreviewDrawer userReviewId={userReview.id} userId={userReview.createdFor} />}
    </>
  );
}

export { GiveReview };
