import React, { useMemo, useEffect } from 'react';

import { IReview, IReviewTheme } from '@learned/types';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { TableGrid } from '~/components/TableGrid';
import { TSecondaryHeaderColumn } from '~/components/TableGrid/types';
import { useToasts, TOAST_TYPES } from '~/components/Toast';
import { MENU_SIZE } from '~/pages/Reports/options';

import { TabBodyWrapper } from './design';
import TableTopControlsSection from './TableTopControlsSection';

import { IColumnTable } from '~/@types/table';
import routes from '~/constants/routes';
import { Pagination } from '~/hooks/usePagination';
import { TSortingOrder } from '~/services/reports';
import { downloadReviewResults, type TGetReviewResults } from '~/services/reviews/reports/results';
import history from '~/utils/history';

import { TReviewResultRow } from '../../types';

interface IResultsQuestionsTableProps {
  review: IReview | undefined;
  onAdvancedReportNavigation: () => void;
  toggleTableViewSize: () => void;
  isMaximizedView: boolean;
  useReviewResultQuestions: {
    getRatingsDataPayload: TGetReviewResults | undefined;
    setFilters: (filters: { search: string; themes: IReviewTheme[] }) => void;
    filters: { search: string; themes: IReviewTheme[] };
    heatMapData: TReviewResultRow[];
    setHeatMapData: (data: TReviewResultRow[]) => void;
    columns: IColumnTable<any>[];
    sortBy: string;
    setSortBy: (sortBy: string) => void;
    secondaryHeaderColumns: TSecondaryHeaderColumn[] | undefined;
    pagination: Pagination['pagination'];
    changePagination: (pagination: Pagination['pagination']) => void;
    totalCount: number;
    setGetRatingsDataPayload: (payload: TGetReviewResults) => void;
    isLoading: boolean;
    isFiltersVisible: boolean;
    setIsFiltersVisible: (value: boolean) => void;
  };
}

/**
 * Displayed for reviews created after Learned 3.0
 * Review Cycle -> Results -> Questions
 */
const ResultsQuestionsTable: React.FC<IResultsQuestionsTableProps> = ({
  review,
  onAdvancedReportNavigation,
  isMaximizedView = false,
  toggleTableViewSize,
  useReviewResultQuestions,
}) => {
  const { i18n } = useLingui();
  const {
    getRatingsDataPayload,
    setFilters,
    filters,
    heatMapData,
    setHeatMapData,
    columns,
    sortBy,
    setSortBy,
    totalCount,
    pagination,
    changePagination,
    setGetRatingsDataPayload,
    isLoading,
    secondaryHeaderColumns,
    isFiltersVisible,
    setIsFiltersVisible,
  } = useReviewResultQuestions;

  useEffect(() => {
    // TODO: check if this is really necessary, if needed we can get it from hook params it seems we can just rely on review id, no need to get the entire review here,
    if (!review) {
      return;
    }
    const sortedArray = sortBy.split('__') || [];

    const getRatingsDataPayload = {
      filters: {
        search: filters.search,
        themes: filters.themes?.map((theme: IReviewTheme) => theme.id),
      },
      sorting: {
        orderBy: sortedArray.length === 2 ? sortedArray[0] : '',
        order: sortedArray.length === 2 ? (sortedArray[1] as TSortingOrder) : ('' as TSortingOrder),
      },
      options: pagination,
      reviewId: review.id,
      measure: 'question',
    } as TGetReviewResults;

    setGetRatingsDataPayload(getRatingsDataPayload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(filters), pagination, review, sortBy]);

  const { addToast } = useToasts();

  const customPaginationList = useMemo(
    () => [
      { id: 5, label: i18n._(t`5 per page`) },
      { id: 10, label: i18n._(t`10 per page`) },
    ],
    [i18n],
  );

  const exportCSV = async () => {
    if (!getRatingsDataPayload) {
      return;
    }
    addToast({
      title: i18n._(t`Exporting CSV`),
      subtitle: i18n._(
        t`Your CSV is being downloaded. This can take some time. It will download when it is ready.`,
      ),
      type: TOAST_TYPES.INFO,
    });

    const { sorting, reviewId, filters } = getRatingsDataPayload;
    await downloadReviewResults(
      {
        reviewId,
        sorting,
        filters,
        options: {},
        measure: 'question',
      },
      'csv',
      'download',
    );
  };

  const onChangeFilters = (key: string, value: unknown) => {
    setFilters({
      ...filters,
      [key]: value,
    });
  };

  const onResetFilters = () => {
    setFilters({ search: '', themes: [] });
  };

  const onRowClick = (clickedItem: TReviewResultRow) => {
    // team row
    if (clickedItem.nestedLevel === 0) {
      const rowsUpdated = heatMapData.map((item) => {
        const isTeam = item.nestedLevel === 0;
        const isTeamMatch = isTeam && item.temporalUniqueId === clickedItem.temporalUniqueId;
        const isEmployeeFromTeam =
          !isTeam && (item as TReviewResultRow).parents?.includes(clickedItem.temporalUniqueId);
        if (isTeamMatch) {
          return {
            ...item,
            isCollapsed: !item.isCollapsed,
          };
        }
        if (isEmployeeFromTeam) {
          return {
            ...item,
            isVisible: !item.isVisible,
          };
        }
        return item;
      });

      setHeatMapData(rowsUpdated);
    } else if (clickedItem.userReviewId) {
      history.push(
        routes.USER_REVIEW_DASHBOARD.build(
          {},
          { userReviewId: clickedItem.userReviewId, isBackPath: true },
        ),
      );
    }
  };

  return (
    <TabBodyWrapper>
      <TableTopControlsSection
        review={review}
        filters={filters}
        onExportCSV={exportCSV}
        isCSVExportVisible={totalCount > 0}
        isMaximizedView={isMaximizedView}
        isFiltersVisible={isFiltersVisible}
        setIsFiltersVisible={setIsFiltersVisible}
        onToggleTableViewSize={toggleTableViewSize}
        onChangeFilters={onChangeFilters}
        onResetFilters={onResetFilters}
        onAdvancedReportNavigation={onAdvancedReportNavigation}
      />

      <TableGrid
        isHeatmapColored={true}
        data={heatMapData?.filter((item) => item.isVisible) || []}
        columns={columns}
        isScrollbarVisible
        rightMinWidth={`${MENU_SIZE.ONLY_AVERAGE}px`}
        secondaryHeaderColumns={secondaryHeaderColumns}
        enableMultipleTableHeaderRows={true}
        isLoading={isLoading}
        onRowClick={onRowClick}
        onColClick={{
          column: 'primaryDimension',
          onClick: onRowClick,
        }}
        paginationProps={{
          pagination,
          changePagination,
          totalCount,
          customPaginationList,
        }}
        leftMinWidth={`${MENU_SIZE.LEFT_FULL_WIDTH + 10}px`}
        setSortBy={(value) => {
          setSortBy(value);
        }}
        showTopArea={false}
        sortBy={sortBy}
        isLeftColumnsStriped
      />
    </TabBodyWrapper>
  );
};

export default ResultsQuestionsTable;
