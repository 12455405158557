import React from 'react';

import { REVIEW_QUESTION_TYPES, REVIEW_RATING_TYPE } from '@learned/constants';

import { TSecondaryHeaderColumn } from '~/components/TableGrid/types';
import {
  CoachCircle,
  PeerCircle,
  SelfCircle,
} from '~/pages/Reviews/DashboardUser/ReviewDashboardUserForm/components/Questions/SkillAnswers/design';

import { ColumnPosition } from '~/@types/table';

import { TResultColumn } from '../../../types';
import { TableColumnSecondTitleContainer } from '../design';

export const createColumnSubHeader = (columns: TResultColumn[]) => {
  const secondaryHeaders: TSecondaryHeaderColumn[] = [];
  const ignoreColumns = ['primaryDimension'];
  columns.forEach((item) => {
    if (ignoreColumns.includes(item.id)) {
      return;
    }
    // goal-plan question does not have reviewer type
    else if (item.questionType === REVIEW_QUESTION_TYPES.GOAL_PLAN) {
      secondaryHeaders.push({
        title: '',
        columnPosition: ColumnPosition.RIGHT,
        centerAlign: true,
        isFixed: false,
      });
    } else {
      if (item.showCoach) {
        secondaryHeaders.push({
          title: () => {
            return (
              <TableColumnSecondTitleContainer>
                <CoachCircle />
                <span>{REVIEW_RATING_TYPE.COACH}</span>
              </TableColumnSecondTitleContainer>
            );
          },
          isFixed: item.id === 'average',
          columnPosition: item.id === 'average' ? ColumnPosition.RIGHT : undefined,
          centerAlign: true,
        });
      }
      if (item.showPeer) {
        secondaryHeaders.push({
          title: () => {
            return (
              <TableColumnSecondTitleContainer>
                <PeerCircle />
                <span>{REVIEW_RATING_TYPE.PEER}</span>
              </TableColumnSecondTitleContainer>
            );
          },
          columnPosition: item.id === 'average' ? ColumnPosition.RIGHT : undefined,
          isFixed: item.id === 'average',
          centerAlign: true,
        });
      }
      if (item.showSelf) {
        secondaryHeaders.push({
          title: () => {
            return (
              <TableColumnSecondTitleContainer>
                <SelfCircle />
                <span>{REVIEW_RATING_TYPE.SELF}</span>
              </TableColumnSecondTitleContainer>
            );
          },
          columnPosition: item.id === 'average' ? ColumnPosition.RIGHT : undefined,
          isFixed: item.id === 'average',
          centerAlign: true,
        });
      }
    }
  });
  return secondaryHeaders;
};
