import React, { useEffect, useMemo, useState } from 'react';

import { CONFIRMATION_MODAL_TYPE, REVIEW_TEMPLATE_STATUSES, ROLES } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { uniq } from 'lodash';
import qs from 'qs';
import { UseFormReturn, useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import styled from 'styled-components';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import DashboardHeader from '~/components/DashboardHeader';
import { MultiLangComponent } from '~/components/Dropdown/MultiLangualDropdown';
import { ICONS } from '~/components/Icon';
import { LastSaved } from '~/components/LastSaved';
import { ConfirmationModal } from '~/components/Modals/ConfirmationModal';
import { SideBar } from '~/components/SideBar';
import { useSectionState } from '~/components/SideBar/SectionStateHook';
import { TOAST_TYPES, useToasts } from '~/components/Toast';
import ToolTip, { TOOLTIP_SIZES } from '~/components/Tooltip';
import { GiveReviewPreview } from '~/pages/ReviewGiveFeedback/components/Preview';
import { isRequiredRatedQuestion } from '~/pages/Reviews/utils';

import { FIELDS } from './constants';
import { ActionItemBlock, Actions, QuestionsWrapper, Wrapper } from './design';
import { General } from './sections/General';
import { Questions } from './sections/Questions';
import { ThemeWeights } from './sections/ThemeWeights';
import { Errors, resolver } from './validations';

import routes from '~/constants/routes';
import { useAutoSaveState } from '~/hooks/useAutoSaveState';
import { useFromQuery } from '~/hooks/useFromQuery';
import { useLanguageState } from '~/hooks/useLanguageState';
import { deleteReviewQuestion, updateReviewQuestion } from '~/services/reviewQuestions';
import { getTemplate, updateTemplate, deleteTemplate } from '~/services/reviewTemplates';
import { COLORS } from '~/styles';
import { generateEvenThemeWeights } from '~/utils/generateEvenThemeWeights';
import { turnArrayIntoMultiLang, turnMultiLangIntoArray } from '~/utils/turnMultiLangIntoArray';

import { SubHeader } from '../ReviewThemeView/components/SubHeader';
import { DASHBOARD_TYPE } from '../ReviewThemeView/constants';

import type { IGeneralForm, ITheme, PopulatedQuestion, PopulatedReviewTemplate } from './types';
import type {
  IMultiLangString,
  IReviewQuestion,
  IReviewTemplate,
  WithPartial,
} from '@learned/types';

let isStopAutoSave = false; // we need to stop autosave (after 10 sec from last change) if template is deleted

const PreviewWrapper = styled.div`
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  z-index: 1000;
  background-color: ${COLORS.BG_PAGE};
  overflow: auto;
`;

const ReviewTemplateSetup = () => {
  const { i18n } = useLingui();
  const languageState = useLanguageState();
  const { goBack } = useFromQuery({ includeHash: true });
  const { addToast } = useToasts();
  const params: Record<string, string | undefined> = useParams();
  const [isDeleteTemplateModalVisible, setIsDeleteTemplateModalVisible] = useState(false);
  const [questions, setQuestions] = useState<Record<PopulatedQuestion['id'], PopulatedQuestion>>(
    {},
  );
  const [showPreview, setShowPreview] = useState(false);
  const [previewTemplate, setPreviewTemplate] = useState({});

  const query = qs.parse(location.search, { ignoreQueryPrefix: true });
  const isCreatingNew = query.isCreatingNew;

  const reviewTemplateId = params.reviewTemplateId as string;

  // Todo: to be implemented
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [oldStatus, setOldStatus] = useState<REVIEW_TEMPLATE_STATUSES>();

  const {
    lastSavedTime,
    lastSavedStatus,
    lastSavedErrorMessage,
    setLastSaveSuccess,
    setLastSaveError,
  } = useAutoSaveState({
    errorMessage: i18n._(t`Please fill all obligated fields`),
  });

  const generalFormMethods: UseFormReturn<IGeneralForm> = useForm<
    IGeneralForm,
    { companyPrimaryLanguage: string }
  >({
    mode: 'onSubmit',
    resolver,
    context: { companyPrimaryLanguage: languageState.companyPrimaryLanguage.locale },
    defaultValues: {
      questions: [],
      themeWeights: [],
      status: REVIEW_TEMPLATE_STATUSES.DRAFT,
      isCustomWeight: true,
    },
  });

  const {
    trigger,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
    getValues,
    setError,
  } = generalFormMethods;

  // Todo: to be implemented
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onSuccess = async (data: IGeneralForm, isPublishing = false, isAutoSave = false) => {
    // Todo: to be implemented
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const transformedData = {
      name: turnArrayIntoMultiLang(data.name.filter((langValue) => langValue.value !== '')),
      themeWeights: data.themeWeights.reduce(
        (acc: IReviewTemplate['themeWeights'], themeWeight) => {
          acc[themeWeight.themeId] = data.isCustomWeight ? themeWeight.weight : 0;
          return acc;
        },
        {},
      ),
      status: isPublishing
        ? REVIEW_TEMPLATE_STATUSES.PUBLISHED
        : (data.status as REVIEW_TEMPLATE_STATUSES),
      questions: data.questions.map((q) => q.questionId),
      isCustomWeight: data.isCustomWeight,
    };

    try {
      const result = await updateTemplate(reviewTemplateId, transformedData);
      if (result.code === 200) {
        setLastSaveSuccess();
        if (!isAutoSave && isPublishing) {
          addToast({ title: i18n._(t`Template published`), type: TOAST_TYPES.SUCCESS });
          goBack();
        } else if (
          !isAutoSave &&
          (data.status as REVIEW_TEMPLATE_STATUSES) === REVIEW_TEMPLATE_STATUSES.PUBLISHED
        ) {
          addToast({ title: i18n._(t`Template saved`), type: TOAST_TYPES.SUCCESS });
          goBack();
        } else if (!isAutoSave) {
          addToast({ title: i18n._(t`Template saved as draft`), type: TOAST_TYPES.INFO });
          goBack();
        }
      } else {
        setLastSaveError();
      }
    } catch {
      return;
    }
  };

  const onFail = (isAutosave = false) => {
    if (!isAutosave) {
      sectionState.setTriedToSubmit();
      addToast({
        title: i18n._(t`Warning`),
        subtitle: i18n._(t`Please fill all obligated fields`),
        type: TOAST_TYPES.INFO,
      });
      setTimeout(() => sectionState.goToFirstErrorSection(), 100);
    }
    setValue('status', oldStatus);
    trigger();
  };

  const onAutosave = async (e?: React.BaseSyntheticEvent) => {
    if (isStopAutoSave) {
      return;
    }
    formatDataBeforeSubmit();

    const submit = handleSubmit(
      (data) => {
        return onSuccess(data, false, true);
      },
      () => onFail(true),
    );
    return submit(e);
  };

  const sectionState = useSectionState([
    {
      title: i18n._(t`General`),
      isTouched: true,
    },
    {
      title: i18n._(t`Questions`),
    },
    {
      title: i18n._(t`Theme weights`),
    },
  ]);

  const nameInPrimaryLanguage = (generalFormMethods.watch(FIELDS.NAME) || [])?.find(
    (name) => name.locale === languageState.companyPrimaryLanguage.locale,
  )?.value;

  const handleFetchedTemplate = async (reviewTemplate: PopulatedReviewTemplate) => {
    const templateQuestions: Record<IReviewQuestion['id'], IReviewQuestion> = {};
    reviewTemplate.questions.forEach((q) => {
      templateQuestions[q.id] = q;
    });
    setOldStatus(reviewTemplate.status);
    setQuestions(templateQuestions);

    const name = turnMultiLangIntoArray(reviewTemplate.name, languageState.companyLanguages);

    const questions = reviewTemplate.questions.map((q) => ({ questionId: q.id }));
    const themeWeights = Object.entries(reviewTemplate.themeWeights).map(([themeId, weight]) => ({
      themeId,
      weight,
      enabled: weight > 0,
    }));

    generalFormMethods.setValue('name', name);
    generalFormMethods.setValue('questions', questions);
    generalFormMethods.setValue('themeWeights', themeWeights);
    generalFormMethods.setValue('status', reviewTemplate.status);
    generalFormMethods.setValue('isCustomWeight', reviewTemplate?.isCustomWeight ?? true);

    await trigger();
  };

  const fetchTemplate = async () => {
    if (!reviewTemplateId) {
      return;
    }
    const { data } = await getTemplate(reviewTemplateId, {
      populate: ['questions'],
      joinToQuestions: ['themeName', 'themeIcon', 'skillCategories'],
    });
    const reviewTemplate: PopulatedReviewTemplate = data.reviewTemplate;
    return reviewTemplate;
  };

  useEffect(() => {
    let mounted = true;
    isStopAutoSave = false;
    const safeFetchTemplate = async () => {
      const reviewTemplate = await fetchTemplate();
      if (mounted && reviewTemplate) {
        await handleFetchedTemplate(reviewTemplate);
      }
    };

    safeFetchTemplate();

    return () => {
      mounted = false;
    };
    // eslint-disable-next-line
  }, [reviewTemplateId]);

  const formatDataBeforeSubmit = () => {
    const isCustomWeight = getValues('isCustomWeight');

    if (!isCustomWeight) {
      equalizeWeights();
    } else {
      setValue(
        'themeWeights',
        getValues('themeWeights').map(({ themeId, weight }) => ({ themeId, weight })),
      );
    }
    trigger('themeWeights');
  };

  const onDeleteTemplate = async () => {
    const response = await deleteTemplate(reviewTemplateId);
    // TODO Replace with "code === 200" when BE is updated
    if (response === 'OK') {
      isStopAutoSave = true; // stop autoSave if template is deleted
      addToast({
        title: i18n._(t`Template deleted!`),
        subtitle: i18n._(t`Your template has been deleted from your company templates`),
        type: TOAST_TYPES.INFO,
      });
      routes.REVIEWS.go(
        {
          role: ROLES.USER,
        },
        {
          hash: 'templates',
        },
      );
    }
  };

  const refreshTemplate = async () => {
    const reviewTemplate = await fetchTemplate();
    if (reviewTemplate) {
      await handleFetchedTemplate(reviewTemplate);
    }
  };

  const themesMap = useMemo(() => {
    const themesMap: Record<string, ITheme> = {};
    Object.values(questions).forEach((q) => {
      if (!q.theme) {
        return;
      }

      themesMap[q.theme] = {
        id: q.theme,
        name: q.themeName as IMultiLangString,
        icon: q.themeIcon as string,
        iconColor: q.themeIconColor as string,
      };
    });

    return themesMap;
  }, [questions]);

  const getWeightedThemes = (themeIds: string[], questionsList?: IReviewQuestion[]) => {
    const themes = themeIds.filter((themeId) => {
      const weightedThemeQuestions = Object.values(questionsList ?? questions).filter(
        (question) =>
          question.theme === themeId && isRequiredRatedQuestion(question as IReviewQuestion),
      );
      return weightedThemeQuestions.length > 0;
    });

    return uniq(themes);
  };

  const equalizeWeights = (themeIds?: string[]) => {
    const themeIdList = themeIds ?? Object.keys(themesMap);
    const weightedThemeIds = getWeightedThemes(themeIdList);
    const newThemeWeights = generateEvenThemeWeights(weightedThemeIds);
    const themeWeights = themeIdList.map((themeId) => {
      return {
        themeId,
        weight: newThemeWeights[themeId] ?? 0,
        enabled: !!(newThemeWeights[themeId] ?? 0),
      };
    });

    setValue('themeWeights', themeWeights);
    trigger('themeWeights');
  };

  const validateWeights = async () => {
    let hasError = false;
    const themeWeights = getValues('themeWeights');
    themeWeights.forEach(({ themeId, weight }, index) => {
      const requiredQuestions = Object.values(questions).filter(
        (question) =>
          question.theme === themeId && isRequiredRatedQuestion(question as IReviewQuestion),
      );

      if (weight > 0 && requiredQuestions.length === 0) {
        setError(`themeWeights.${index}`, {
          message: Errors.notAllowed,
        });
        hasError = true;
      }
    });

    if (hasError) {
      sectionState.setTriedToSubmit();
    }

    return !hasError;
  };

  const onPublish = async (e?: React.BaseSyntheticEvent) => {
    e?.stopPropagation();
    e?.preventDefault();
    setValue('status', REVIEW_TEMPLATE_STATUSES.PUBLISHED);

    const isValid = await validateWeights();

    if (!isValid) {
      setValue('status', oldStatus);
      sectionState.goToFirstErrorSection();
      return;
    }

    formatDataBeforeSubmit();

    const submit = handleSubmit(
      (data) => {
        return onSuccess(data, true);
      },
      () => onFail(),
    );

    return submit(e);
  };

  useEffect(() => {
    sectionState.setErrorSection(0, !!errors?.name);
    // eslint-disable-next-line
  }, [errors?.name]);

  useEffect(() => {
    sectionState.setErrorSection(1, !!errors?.questions);
    // eslint-disable-next-line
  }, [errors?.questions]);

  useEffect(() => {
    sectionState.setErrorSection(2, !!errors?.themeWeights);
    // eslint-disable-next-line
  }, [errors?.themeWeights]);

  const onSave = async (e?: React.BaseSyntheticEvent) => {
    e?.stopPropagation();
    e?.preventDefault();
    if (getValues('status') === REVIEW_TEMPLATE_STATUSES.PUBLISHED) {
      const isValid = await validateWeights();

      if (!isValid) {
        setValue('status', oldStatus);
        sectionState.goToFirstErrorSection();
        return;
      }
    }

    formatDataBeforeSubmit();

    const submit = handleSubmit(
      (data) => {
        return onSuccess(data, false);
      },
      () => onFail(),
    );

    return submit(e);
  };

  const status = watch('status');

  const updateQuestion = async (
    question: Omit<WithPartial<IReviewQuestion, 'name' | 'type'>, 'company' | 'meta'>,
  ) => {
    if (!question) {
      return;
    }
    const newQuestions = { ...questions };
    // @ts-ignore
    newQuestions[question.id] = {
      ...newQuestions[question.id],
      ...question,
    };
    updateReviewQuestion(question.id as string, question);
    setPreviewTemplate({
      name: turnArrayIntoMultiLang(generalFormMethods.watch(FIELDS.NAME)),
      description: generalFormMethods.watch('description'),
      questions: Object.values(newQuestions),
      status,
    });
  };

  const deleteQuestion = async (questionId: string) => {
    const newQuestions = { ...questions };
    delete newQuestions[questionId];
    setPreviewTemplate({
      name: turnArrayIntoMultiLang(generalFormMethods.watch(FIELDS.NAME)),
      description: generalFormMethods.watch('description'),
      questions: Object.values(newQuestions),
      status,
    });
    deleteReviewQuestion(questionId);
  };

  if (showPreview && Object.values(questions).length) {
    return (
      <PreviewWrapper>
        <GiveReviewPreview
          onClose={() => setShowPreview(false)}
          //       @ts-ignore
          template={previewTemplate}
          deleteQuestion={deleteQuestion}
          updateQuestion={updateQuestion}
          languageState={languageState}
        />
      </PreviewWrapper>
    );
  }

  return (
    <>
      <DashboardHeader
        title={`${
          isCreatingNew ? i18n._(t`Creating a new template`) : i18n._(t`Edit template`)
        }: ${nameInPrimaryLanguage}`}
        onBack={async () => {
          await onSave();
          goBack();
        }}
        actions={
          <Actions>
            <LastSaved
              time={lastSavedTime}
              status={lastSavedStatus}
              errorMessage={lastSavedErrorMessage}
            />
            <ActionItemBlock>
              <MultiLangComponent languageState={languageState} />
            </ActionItemBlock>
            <ActionItemBlock>
              <ToolTip size={TOOLTIP_SIZES.BIG} disabled={false} tooltip={i18n._(t`Delete`)}>
                <span>
                  <Button
                    label=""
                    size={ButtonSize.MEDIUM}
                    variant={ButtonVariant.ICON_DELETE}
                    onClick={() => {
                      setIsDeleteTemplateModalVisible(true);
                    }}
                  />
                </span>
              </ToolTip>
              <ToolTip
                size={TOOLTIP_SIZES.BIG}
                disabled={false}
                tooltip={
                  Object.values(questions).length
                    ? i18n._(t`Preview`)
                    : i18n._(t`You need to add questions to preview template`)
                }
              >
                <span>
                  <Button
                    label=""
                    disabled={!Object.values(questions).length}
                    icon={ICONS.SHOW}
                    size={ButtonSize.MEDIUM}
                    variant={ButtonVariant.ICON}
                    onClick={() => {
                      setShowPreview(true);
                      setPreviewTemplate({
                        name: turnArrayIntoMultiLang(generalFormMethods.watch(FIELDS.NAME)),
                        description: generalFormMethods.watch('description'),
                        questions: Object.values(questions),
                        status,
                      });
                    }}
                  />
                </span>
              </ToolTip>
              <ToolTip
                size={TOOLTIP_SIZES.BIG}
                disabled={false}
                tooltip={isCreatingNew ? i18n._(t`Save draft`) : i18n._(t`Save`)}
              >
                <span>
                  <Button
                    label=""
                    icon={ICONS.SAVE}
                    size={ButtonSize.MEDIUM}
                    variant={ButtonVariant.ICON}
                    onClick={onSave}
                  />
                </span>
              </ToolTip>
            </ActionItemBlock>
            {status === REVIEW_TEMPLATE_STATUSES.DRAFT && (
              <ActionItemBlock>
                <Button
                  label={i18n._(t`Publish`)}
                  size={ButtonSize.MEDIUM}
                  variant={ButtonVariant.PRIMARY}
                  onClick={onPublish}
                />
              </ActionItemBlock>
            )}
          </Actions>
        }
        subHeader={
          status === REVIEW_TEMPLATE_STATUSES.DRAFT && (
            <SubHeader dashboardType={DASHBOARD_TYPE.TEMPLATE} />
          )
        }
      />
      <Wrapper>
        <SideBar
          sections={sectionState.sections}
          currentSection={sectionState.currentSection}
          setCurrentSection={sectionState.setCurrentSection}
          hideErrorState={!sectionState.triedToSubmit}
        />
        {sectionState.currentSection === 0 && (
          <General
            languageState={languageState}
            formMethods={generalFormMethods}
            setCurrentSection={sectionState.setCurrentSection}
            triedToSubmit={sectionState.triedToSubmit}
            autoSave={onAutosave}
          />
        )}
        {sectionState.currentSection === 1 && (
          <QuestionsWrapper>
            <Questions
              formMethods={generalFormMethods}
              setCurrentSection={sectionState.setCurrentSection}
              autoSave={onAutosave}
              languageState={languageState}
              setQuestions={setQuestions}
              setEditQuestion={() => {}} // Todo: implement in future MRs
              refreshTemplate={refreshTemplate}
              reviewTemplateId={reviewTemplateId}
              questions={questions}
              themeIds={Object.keys(themesMap)}
              equalizeWeights={equalizeWeights}
              getWeightedThemes={getWeightedThemes}
            />
          </QuestionsWrapper>
        )}
        {sectionState.currentSection === 2 && (
          <ThemeWeights
            questions={Object.values(questions)}
            formMethods={generalFormMethods}
            themesMap={themesMap}
            sectionState={sectionState}
            autosave={onAutosave}
            equalizeWeights={equalizeWeights}
            onSubmit={onPublish}
          />
        )}
      </Wrapper>
      {isDeleteTemplateModalVisible && (
        <ConfirmationModal
          type={CONFIRMATION_MODAL_TYPE.DELETE}
          onClose={() => setIsDeleteTemplateModalVisible(false)}
          title={i18n._(t`Delete template?`)}
          description={i18n._(
            t`Are you sure you want to delete this template? This action cannot be undone.`,
          )}
          onSubmit={onDeleteTemplate}
        />
      )}
    </>
  );
};

export { ReviewTemplateSetup };
