import { TData, TReportRow } from '~/pages/Reports/types';

// TODO: I was unable to find type definitions for review type, once it's implemented, we may remove this type def
// and use Partial utility type to extract only required fields
export type TPartialReviewData = {
  id: string;
  settings: {
    startDate: string;
    endDate: string;
  };
};

export type TResultColumn = {
  id: string;
  name: string;
  questionType: string;
  theme: {
    icon: string | null;
    iconColor: string;
    name: string;
    weight: string;
  };
  showCoach: boolean;
  showPeer: boolean;
  showSelf: boolean;
  isVisible: boolean;
  rlc: number;
  isPercentage: boolean;
};

export type TReviewResultRow = TReportRow & {
  id: string;
  name?: string;
  cells: {
    measure: string; // columnId
    value: number;
    peer?: number;
    self?: number;
    rlc?: number; // stands for ratingLabelsCount
  }[];
  userReviewId?: string;
  children?: TReviewResultRow[];
};

export enum RCD_RESULTS_TABS_ENUM {
  AVERAGE = 'results-average',
  QUESTIONS = 'results-questions',
}

export type TReviewTableData = TData & { rows: TReviewResultRow[]; columns: TResultColumn[] };
